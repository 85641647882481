import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`A few times now, I've had to get around an issue that happens on macOS Catalina where you try to run a Python script and it fails with an import error:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`ImportError: No module named zlib`}</span></code></pre>
    <p>{`The stacktrace will differ based on what was trying to import zlib, but the fix for me (on the most recent Catalina builds) was to reinstall python2 or python3 via brew. As some `}<a parentName="p" {...{
        "href": "https://stackoverflow.com/questions/32630253/import-error-no-module-named-zlib-brew-installed-python"
      }}>{`others have noted`}</a>{`, it's important to `}<inlineCode parentName="p">{`xcode-select --install`}</inlineCode>{` first, if you haven't.`}</p>
    <p>{`If the script is Python 2:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`brew reinstall python2`}</span></code></pre>
    <p>{`If the script is Python 3:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": ""
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`brew reinstall python3`}</span></code></pre>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      